
body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-top: 200px;

  &.home {
    padding-top: 0;

    @include media-breakpoint-down(sm) {
      padding-top: 50px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: 80px;
  }
}

.uniform__potty {
  position: absolute;
  left: -9999px;
}

.img-fluid {
  min-width: 100%;
}

a {
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
  }
}

h1 {
  font-family: 'Barlow', sans-serif;
  font-size: 56px;
  margin-bottom: 140px;
  position: relative;
  text-transform: uppercase;

  &:after {
    content: "";
    position: absolute;
    width: 117px;
    height: 1px;
    background: $primary;
    bottom: -45px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 80px;
    font-size: 32px;
    &:after {
      bottom: -25px;
      transform: translateX(-50%);
    }
  }
}

h2 {
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
}

h3 {
  font-family: 'Barlow', sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

h4 {
  font-family: 'Barlow', sans-serif;
}

.bg {
  background: $bg;
}


.content {
  padding-bottom: 120px;
}

.title-small {
  font-family: 'Barlow', sans-serif;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  color: $primary;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: left;

  &:after {
    display: none;
  }
}

.jumbo-bg {
  background-color: $jumbo;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.jumbo {
  padding-top: 240px;
  padding-bottom: 60px;
  color: $white;

  @include media-breakpoint-down(sm) {
    padding-top: 60px;
  }
}

.grid-sizer {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 48%;
  }
}
.gutter-sizer {
  width: 4%;
}

.grid-item {
  margin-bottom: 20px;
  width: 100%;
  display: block;
  color: $white;

  &:hover {
    color: $white;
  }

  @include media-breakpoint-up(md) {
    width: 48%;
    margin-bottom: 40px;
  }

  h2 {
    text-align: left;
    display: block;
    padding-bottom: 10px;
    font-size: 24px;
    margin: 0;
  }
}

.home-lead {
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
  font-size: 24px;
}
