.release {

  iframe {
    max-width: 100%;
    min-width: 100%;
    height: auto;

    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }
  }
}

.release-add {
  padding-top: 80px;
  padding-bottom: 160px;

  @include media-breakpoint-down(md) {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}

.release-cover {
  margin-bottom: 80px;

  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
  }
}

.release-infos {

  h1 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;

    .artist {
      color: $primary;
    }

    &:after {
      display: none;
    }
  }
}
.release-date {
  margin-bottom: 30px;
}
.release-shoplinks {
  margin-bottom: 30px;
}
.release-presstext {
  margin-bottom: 30px;
}
.release-feedbacks {

  &.collapse {
    display: block;
    max-height: 190px;
    overflow: hidden;

    &.show {
      max-height: none;
    }
  }
}

.release-all-reviews {
  display: inline-block;
  margin-top: 10px;
}
