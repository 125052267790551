.releases {
  .release {
    margin-bottom: 60px;

    a {
      display: block;

      img {
        display: block;
        @include hover-zoom();
      }
    }
  }
  .release-artist {
    font-family: 'Barlow', sans-serif;
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    color: $primary;
    margin-top: 20px;
  }
  .release-title {
    font-family: 'Barlow', sans-serif;
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
  }
}
