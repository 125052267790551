footer {
  background: linear-gradient(to bottom, $footer, $footer, darken($footer, 3%));
  min-height: 160px;
  color: $white;
  padding-top: 20px;
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
  padding-top: 60px;

  .social {
    a {
      font-size: 60px;
      color: $white;

      &:hover {
        color: $primary;
      }
    }

    padding-bottom: 120px;
  }
}

.footer-meta {
  font-size: 14px;
  padding-bottom: 15px;

  a {
    color: $white;
  }
}
