.logo {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  height: 100px;
  transition: all 0.5s;

  .home & {
    height: 150px;
    top: 120px;
  }

  .shrink & {
    height: 60px;
    top: 50%;
  }
}
