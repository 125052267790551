.events {

}

.events-list {

  li {
    width: 100%;
    display: block;
    border-left: 3px solid $primary;
    @include clearfix;

    > a {
      .col-auto {
        margin: 0;
        padding: 30px 15px;
      }
    }
  }
}

.event-list-item {
  display: block;
  color: $white;
  margin-bottom: 30px;
  background: linear-gradient(to right, rgba($secondary, 0.94), darken($secondary, 3%));

  &.home {
    width: auto;
    padding: 20px;
    transform: translate3d(0,0,0);

    &:hover {
      transform: none;
      opacity: 1;
    }

    .event-date {
      text-align: left;

      span {
        text-align: left;
      }
    }
  }
}

.event-date {
  text-align: center;
  font-family: Barlow;
  font-size: 40px;
  font-weight: 800;
  line-height: 29px;
  color: $white;

  span {
    display: block;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: 3.2px;
    text-align: center;
    text-transform: uppercase;
    color: $primary;
    margin-top: 5px;
  }
}

.event-title {
  color: $white;
  min-width: 45%;

  span {
    text-transform: uppercase;
    font-family: Barlow;
    font-size: 18px;
    line-height: 1;
    letter-spacing: -0.4px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 20px;
  }
}

.event-venue {
  text-align: right;
  line-height: 1.83;
  color: $primary;
}
