.artists {


  .content-artists {
    padding-bottom: 20px;
  }

  .artist {
    margin-bottom: 30px;

    img {
      display: block;
    }

    @include hover-zoom-image-only();

    .card-title {
      color: $primary;
    }
  }
}
