
.navigation {
  background: linear-gradient(to top, rgba($navigation, 0.94), rgba($navigation, 0.94), rgba($navigation, 0.94), darken($navigation, 2%));
  //background: rgba($secondary, 0.94);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 140px;
  transition: all 0.5s;
  transform:translateZ(0);

  .home & {
    background: rgba($navigationHome, 0);
  }


  ul {
    margin: 0;
    vertical-align: middle;

    li {
      a {
        margin-right: 30px;
        font-family: 'Barlow', sans-serif;
        text-transform: uppercase;
        color: $white;
        display: inline-block;
        transition: color 0.3s;

        &.active,
        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }
    }
  }

  &.shrink {
    height: 70px;

    .home & {
      background: rgba($navigationHome, 0.9);
    }
  }
}

.navigation-list {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}


.navbar-toggler {
  position: relative;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler span {
   display: block;
   background-color: $white;
   height: 2px;
   width: 20px;
   margin-top: 4px;
   margin-bottom: 4px;
   transform: rotate(0deg);
   position: relative;
   left: 0;
   opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
   transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 13px;
    top: 10px;
    transform: rotate(135deg);
    opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    top: 10px;
    left: 13px;
    transform: rotate(-135deg);
    opacity: 0.9;
}

.mobile-navigation {
  padding: 0;
  background: rgba($secondary, 0.9);
  min-height: 45px;

  .navbar-brand {
    position: relative;
    left: 10px;
  }

  ul {
    display: block;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    height: 45px;
    text-align: center;

    a {
      display: block;
      min-height: 45px;
      line-height: 45px;
      font-family: 'Barlow', sans-serif;
      text-transform: uppercase;
      color: $white;
      display: inline-block;
      transition: color 0.3s;
      color: $white;

      &.active,
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}

.navbar-collapse {
  ul.nav {
    padding-bottom: 20px;
  }
}
