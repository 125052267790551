$primary:#e83723; //#c41917;
$secondary: #2c4552;
$jumbo: #000000;
$navigationHome: lighten(#000000, 12%);
$navigation: $secondary;
$footer: $secondary;
$bg: #ECEBEB;

$font-family-sans-serif: 'Roboto', sans-serif;

$border-radius: 0;
