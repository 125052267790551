@mixin hover-zoom() {
  opacity: 1;
  transition: all 0.3s;
  backface-visibility: hidden;

  &:hover {
    transform: scale(1.02);
    opacity: 0.8;
  }
}

@mixin hover-zoom-image-only() {
  img {
    opacity: 1;
    transition: all 0.3s;
    backface-visibility: hidden;
  }

  &:hover
  {
    img {
      transform: scale(1.02);
      opacity: 0.8;
    }
  }
}

@mixin hover-zoom-event-only() {
  .events-list {
    opacity: 1;
    transition: all 0.3s;
    backface-visibility: hidden;
  }

  &:hover
  {
    .events-list {
      transform: scale(1.02);
      opacity: 0.8;
    }
  }
}

.hover-zoom {
  @include hover-zoom();
}

.hover-zoom-image-only {
  @include hover-zoom-image-only();
}

.hover-zoom-event-only {
  @include hover-zoom-event-only();
}
